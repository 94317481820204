import React from 'react';
import type { Account } from '@wilm/shared-types/account';
import LoginForm from './login-form';

export interface LoginProps {
    onLogin?: (account: Account) => void;
    registrationRedirectTextContent: string;
    registrationRedirectButtonText: string;
    registrationNoteContent: string;
}

const Login: React.FC<LoginProps> = ({
    onLogin,
    registrationRedirectTextContent,
    registrationRedirectButtonText,
    registrationNoteContent
}) => {
    return (
        <LoginForm
            onLogin={onLogin}
            registrationRedirectTextContent={registrationRedirectTextContent}
            registrationRedirectButtonText={registrationRedirectButtonText}
            registrationNoteContent={registrationNoteContent}
        />
    );
};

export default Login;
